import { IRootStore } from './root-store';

export interface IReviewStore {}

export class ReviewStore implements IReviewStore {
  private root: IRootStore;
  constructor(root: IRootStore) {
    this.root = root;
  }
}
