import { action, observable } from 'mobx';
import { Locale } from '../models/locale';
import { IRootStore } from './root-store';

export interface IVmStore {
  lang: Locale;
  smallNavOpen: boolean;
  setLang(lang: Locale);
  setSmallNavOpen(isOpen: boolean);
}

export class VmStore implements IVmStore {
  @observable
  lang: Locale = Locale.EN;
  @observable
  smallNavOpen: boolean = false;

  private root: IRootStore;
  constructor(root: IRootStore) {
    this.root = root;
  }

  @action
  setLang(lang: Locale) {
    this.lang = lang;
  }

  @action
  setSmallNavOpen(isOpen: boolean) {
    this.smallNavOpen = isOpen;
  }
}
