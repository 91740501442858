import { useLocalStore } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { createRootStore, IRootStore, RootStore } from './root-store';

const storeContext = React.createContext<IRootStore>(null);

export const StoreProvider: FunctionComponent = ({ children }) => {
  const store = new RootStore();
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

// tslint:disable-next-line:only-arrow-functions
export const useStore = function<T = IRootStore>(selector?: (s: IRootStore) => T): T {
  const store = React.useContext(storeContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  if (!selector) {
    selector = (s) => {
      return (s as unknown) as T;
    };
  }
  return selector(store);
};
