import { IMusicStore, MusicStore } from './music-store';
import { IReviewStore, ReviewStore } from './review-store';
import { IVmStore, VmStore } from './vm-store';

export interface IRootStore {
  music: IMusicStore;
  review: IReviewStore;
  vm: IVmStore;
}

export class RootStore implements IRootStore {
  music: IMusicStore;
  review: IReviewStore;
  vm: IVmStore;

  constructor() {
    this.music = new MusicStore(this);
    this.review = new ReviewStore(this);
    this.vm = new VmStore(this);
  }
}

export const createRootStore = (initialState?: IRootStore) => {
  return new RootStore();
};
